.herocareer {
  /* height: 700px; */
  background-image: linear-gradient(
      to right,
      rgba(17, 19, 30, 0.95),
      rgba(17, 19, 30, 0.95)
    ),
    url("../../components/Assets/Images/great.jpg");
  padding: 100px 0;
  text-align: center;

  /* background-image: url("map.jpg"); */
  background-size: cover;
  background-repeat: repeat-x;
  color: white;
  /* overflow: hidden; */
}

.career-header-text {
  padding-top: 100px;
  font-size: 50px;
  font-weight: 500;
}

.career {
  padding-top: 100px;
  padding-bottom: 50px;
  /* padding-left: 0px; */
}

.career h3 {
  font-size: 45px;
  font-weight: 300;
  font-style: normal;
  color: #222;
}

.career p {
  font-size: 15px;
  font-weight: 300px;
  line-height: 24px;
  font-style: normal;
  color: #222;
  padding-top: 20px;
}

.career .better {
  margin-top: 100px;
}

.career img {
  width: 600px;
  margin-bottom: 100px;
}

@media (max-width: 479px) {
  .career {
    padding-top: 50px;
    padding-left: 50px;
    padding-bottom: 50px;
  }

  .career img {
    padding-top: 50px;
    width: 250px;
    margin-right: 20px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bar {
  margin-left: 0px;
}

.brand {
  margin-left: 0px;
}
/* #2b2828; #1e1b1b */

.navbar-header {
  background-color: #1e1b1b;
}

/* .nav-item:hover .dropdown-menu {
  display: block !important;
} */

#nav-text {
  /* background-color: green; */
  color: rgb(160, 156, 156);
}

.hero img {
  height: 900px;
  width: inherit;
  opacity: 0.5;
}

.images {
  background-image: linear-gradient(
    to right,
    rgba(17, 19, 30, 1),
    rgba(17, 19, 30, 1)
  );
}

.heroText {
  margin-bottom: 350px;
}

.heroText h3 {
  font-size: 60px;
  font-weight: 500;
}

.heroText p {
  font-size: 30px;
  font-weight: 300;
}

@media (max-width: 479px) {
  .hero img {
    height: 400px;
    width: inherit;
    opacity: 0.5;
  }

  .heroText {
    margin-bottom: 50px;
  }

  .heroText h3 {
    font-size: 30px;
    font-weight: bold;
  }

  .heroText p {
    font-size: 20px;
  }
  
}

.whoarewe {
  width: 70%;
}

.whoarewe h3 {
  font-weight: 300;
  font-size: 40px;
}

.whoarewe p {
  padding-top: 20px;
  font-weight: 250;
  font-size: 16px;
  color: black;
}

.mission h5 {
  font-weight: 300;
  font-size: 26px;
  text-align: center;
}

.mission p {
  font-weight: 250;
  font-size: 16px;
  color: black;
  text-align: center;
}

.mission h2 {
  font-size: 100px;
  color: #d8855c;
  text-align: center;
}

.service {
  /* height: 500px; */
  padding-top: 100px;
  margin-top: 100px;
  background-color: rgb(246, 248, 251);
}

.service .head {
  width: 60%;
}

.service h3 {
  font-weight: 300;
  font-size: 40px;
}

.service p {
  padding-top: 20px;
  font-weight: 250;
  font-size: 16px;
  color: black;
}

.category {
  padding-top: 50px;
}

.category img {
  height: 500px;
  opacity: 0.03;
  margin-top: -200px;
  overflow: hidden;
}

.category .roww {
  margin-top: -250px;
  padding-bottom: 100px;
}

.category h5 {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}

.category p {
  font-weight: 250;
  font-size: 15px;
  color: black;
  text-align: center;
}

.category h2 {
  font-size: 100px;
  color: #d8855c;
  text-align: center;
}

.guarantee {
  /* height: 500px; */
  padding-top: 50px;
  margin-top: 100px;
  padding-bottom: 100px;
  width: 50%;
}

.guarantee h3 {
  font-weight: 300;
  font-size: 40px;
}

.guarantee p {
  padding-top: 20px;
  font-weight: 250;
  font-size: 16px;
  color: black;
}

@media (max-width: 479px) {
  .whoarewe {
    width: 100%;
  }
  .whoarewe h3 {
    font-weight: 400;
  }

  .whoarewe p {
    padding-top: 20px;
    font-weight: 200;
    font-size: 17px;
  }
  .service .head {
    width: 100%;
  }
  .category img {
    height: 250px;
    opacity: 0.03;
    margin-top: -100px;
    overflow: hidden;
  }
  .category .roww {
    margin-top: -150px;
  }

  .guarantee {
    /* height: 500px; */
    padding-top: 50px;
    margin-top: 100px;
    padding-bottom: 100px;
    width: 80%;
  }
}

.footer {
  /* height: 200px; */
  color: white;
  background-color: black;
}

.footer .words {
  padding-top: 50px;
  padding-bottom: 10px;
}

.words img {
  height: 50px;
  margin-bottom: -100px;
}

.words p {
  font-weight: 300;
  font-size: 15px;
  color: white;
  text-align: left;
}

.words p a {
  color: white;
}

@media (max-width: 479px) {
  .words img {
    /* margin-top: -300px; */
    height: 50px;
    padding-top: 10px;
  }

  .foot h3 {
    padding-top: 100px;
  }
}

.heroservice {
  /* height: 700px; */
  background-image: linear-gradient(
      to right,
      rgba(17, 19, 30, 0.7),
      rgba(17, 19, 30, 0.7)
    ),
    url(/static/media/training.eca33a7c.jpg);
  padding: 100px 0;
  text-align: center;

  /* background-image: url("map.jpg"); */
  background-size: cover;
  color: white;
  /* overflow: hidden; */
}

.service-header-text {
  padding-top: 100px;
  font-size: 42px;
  font-weight: 500;
}

.proud {
  padding-top: 100px;
  /* padding-left: 0px; */
}

.head-text {
  margin-top: 0px;
}

#solve {
  font-size: 80px;
  font-weight: 500;
  color: rgba(203, 38, 19, 0.605);
}

#problem {
  font-size: 80px;
  font-weight: 500;
  color: grey;
}

.staffing {
  padding-top: 5px;
}

.staffing #head {
  font-weight: 300;
  font-size: 16px;
  color: rgb(250, 0, 42);
}

.staffing #middle {
  margin-top: -10px;
  font-weight: 300;
  color: black;
  font-size: 30px;
}

.staffing #body {
  margin-top: -10px;
  font-weight: 400;
  color: grey;
  font-size: 16;
}

.servicestart {
  padding-top: 50px;
  background-image: linear-gradient(
      to right,
      rgba(17, 19, 30, 0.5),
      rgba(17, 19, 30, 0.5)
    ),
    url(/static/media/solution.96f494c7.jpg);
  /* background-color: rgba(203, 38, 19, 0.7); */
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.startcontainer {
  padding-bottom: 100px;
}

.servicestart h3 {
  color: white;
  font-size: 35px;
  font-weight: 700;
  padding-top: 50px;
}

.servicestart p {
  width: 100%;
  color: rgb(235, 221, 221);
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 479px) {
  .proud {
    padding-top: 50px;
    padding-left: 50px;
  }
  .head-text {
    margin-top: 0px;
  }
  #solve {
    font-size: 36px;
    font-weight: 700;
    color: rgba(203, 38, 19, 0.605);
  }
  #problem {
    font-size: 36px;
    font-weight: 700;
    color: grey;
  }
  .staffing {
    padding-top: 50px;
  }
  .startcontainer {
    padding-bottom: 100px;
    padding-left: 40px;
  }
}

.herotraining {
  /* height: 700px; */
  background-image: linear-gradient(
      to right,
      rgba(17, 19, 30, 0.7),
      rgba(17, 19, 30, 0.7)
    ),
    url(/static/media/team.edb82985.jpg);
  padding: 100px 0;
  text-align: center;

  /* background-image: url("map.jpg"); */
  background-size: cover;
  color: white;
  /* overflow: hidden; */
}

.training-header-text {
  padding-top: 100px;
  font-size: 42px;
  font-weight: 500;
}

.train {
  padding-top: 50px;
  background-image: linear-gradient(
      to right,
      rgb(87, 151, 145),
      rgb(73, 126, 168)
    ),
    url(/static/media/solution.96f494c7.jpg);
  /* background-color: rgba(203, 38, 19, 0.7); */
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.train h3 {
  color: white;
  font-size: 44px;
  font-weight: 600;
  padding-top: 10px;
}

.train p {
  width: 100%;
  color: white;
  font-size: 20px;
  font-weight: 200;
  padding-bottom: 100px;
}

.preparing {
  margin-top: -30px;
  padding-top: 20px;
  background-color: #262c39;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.preparing h4 {
  padding-top: 10px;
  color: orange;
  font-weight: bold;
  font-size: 15px;
}

.preparing h3 {
  color: white;
  font-size: 17px;
  font-weight: bold;
}

.preparing p {
  color: white;
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 20px;
}

.trainbody {
  padding-top: 50px;
  padding-bottom: 50px;
}

.trainbody h4 {
  font-size: 18px;
  font-weight: 300;
}

.trainbody h3 {
  padding-top: 10px;
  font-size: 20px;
  font-weight: 700;
}

.trainbody li,
p {
  font-size: 14px;
  font-weight: 400;
}

.trainbody iframe {
  width: 420px;
  height: 315px;
}

@media (max-width: 479px) {
  .train h3 {
    color: white;
    font-size: 40px;
    font-weight: 400;
    padding-top: 10px;
  }
  .train p {
    width: 100%;
    color: white;
    font-size: 15px;
    font-weight: 300;
    padding-bottom: 100px;
  }
  .trainbody iframe {
    width: 300px;
    height: 315px;
  }
}

.heroabout {
  /* height: 700px; */
  background-image: linear-gradient(
      to right,
      rgba(17, 19, 30, 0.7),
      rgba(17, 19, 30, 0.7)
    ),
    url(/static/media/qualityresult.0fce05d5.jpg);
  padding: 50px 0;
  text-align: center;

  /* background-image: url("map.jpg"); */
  background-size: cover;
  color: white;
  /* overflow: hidden; */
}

.about-header-text {
  padding-top: 100px;
  font-size: 42px;
  font-weight: 500;
}

.aboutheadercontainer {
  width: 90%;
  font-size: 30px;
  font-weight: 500;
  color: grey;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.who {
  padding: 120px 0;
  text-align: center;
}

.whobg {
  font-size: 46px;
  font-weight: 700;
}

.team {
  /* height: 700px; */
  background-image: linear-gradient(
    to right,
    rgba(168, 172, 197, 0.3),
    rgba(255, 255, 255, 0.5)
  );
  padding: 120px 0;

  /* background-image: url("map.jpg"); */
  background-size: cover;
  color: white;
}

.team-head {
  font-size: 45px;
  color: black;
  font-weight: 500;
}

.profilecard {
  padding-top: 30px;
  height: 350px;
  background-color: rgba(17, 19, 30, 0.96);

  text-align: center;
}
.team-image img {
  border-radius: 30%;
  height: 200px;
}

.profile-name {
  padding-top: 15px;
  font-size: 25px;
  font-weight: 600;
  color: white;
}
.profile-rank {
  padding-top: 10px;
  font-size: 15px;
  font-weight: 600;
  color: white;
}

.start {
  padding-top: 50px;
  background-image: linear-gradient(
      to right,
      rgba(17, 19, 30, 0.8),
      rgba(17, 19, 30, 0.8)
    ),
    url(/static/media/qualityresult.0fce05d5.jpg);
  /* background-color: rgba(203, 38, 19, 0.7); */
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.startcontainer {
  padding-bottom: 100px;
}

.start h3 {
  color: white;
  font-size: 35px;
  font-weight: 700;
  padding-top: 50px;
}

.start p {
  width: 50%;
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.button1 {
  width: 250px;
  height: 80px;
  margin-right: 20px;
  margin-top: 20px;
  color: rgba(17, 19, 30, 0.8);
}

.button2 {
  width: 250px;
  height: 80px;
  padding-top: 25px;
  margin-right: 20px;
  margin-top: 20px;
  color: white;
  background-color: #d8855c;
}

@media (max-width: 479px) {
  .profilecard {
    /* padding-top: 30px; */
    margin-bottom: 10px;
    height: 350px;
    background-color: rgba(17, 19, 30, 0.96);

    text-align: center;
  }

  .start h3 {
    color: white;
    font-size: 30px;
    font-weight: 600;
    padding-top: 30px;
  }

  .start p {
    width: 80%;
    color: white;
    font-size: 15px;
    font-weight: 500;
    padding-top: 30px;
  }

  .button1 {
    width: 200px;
    height: 60px;
    margin-right: 20px;
    margin-top: 20px;
    color: rgba(17, 19, 30, 0.8);
  }

  .button2 {
    width: 200px;
    height: 60px;
    margin-right: 20px;
    margin-top: 20px;
    color: white;
    background-color: #d8855c;
  }
}

.herocareer {
  /* height: 700px; */
  background-image: linear-gradient(
      to right,
      rgba(17, 19, 30, 0.95),
      rgba(17, 19, 30, 0.95)
    ),
    url(/static/media/great.d4d9b172.jpg);
  padding: 100px 0;
  text-align: center;

  /* background-image: url("map.jpg"); */
  background-size: cover;
  background-repeat: repeat-x;
  color: white;
  /* overflow: hidden; */
}

.career-header-text {
  padding-top: 100px;
  font-size: 50px;
  font-weight: 500;
}

.career {
  padding-top: 100px;
  padding-bottom: 50px;
  /* padding-left: 0px; */
}

.career h3 {
  font-size: 45px;
  font-weight: 300;
  font-style: normal;
  color: #222;
}

.career p {
  font-size: 15px;
  font-weight: 300px;
  line-height: 24px;
  font-style: normal;
  color: #222;
  padding-top: 20px;
}

.career .better {
  margin-top: 100px;
}

.career img {
  width: 600px;
  margin-bottom: 100px;
}

@media (max-width: 479px) {
  .career {
    padding-top: 50px;
    padding-left: 50px;
    padding-bottom: 50px;
  }

  .career img {
    padding-top: 50px;
    width: 250px;
    margin-right: 20px;
  }
}

.herocontactus {
  /* height: 700px; */
  background-image: linear-gradient(
      to right,
      rgba(17, 19, 30, 0.7),
      rgba(17, 19, 30, 0.7)
    ),
    url(/static/media/contact.5e4b916e.jpg);
  padding: 100px 0;
  text-align: center;

  /* background-image: url("map.jpg"); */
  background-size: cover;
  color: white;
  /* overflow: hidden; */
}

.contact-header-text {
  padding-top: 100px;
  font-size: 42px;
  font-weight: 500;
}

.contact-form {
  padding-top: 100px;
  padding-bottom: 100px;
}

.contact-form h3 {
  font-weight: 300;
}


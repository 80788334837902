.herocontactus {
  /* height: 700px; */
  background-image: linear-gradient(
      to right,
      rgba(17, 19, 30, 0.7),
      rgba(17, 19, 30, 0.7)
    ),
    url("../../components/Assets/Images/contact.jpg");
  padding: 100px 0;
  text-align: center;

  /* background-image: url("map.jpg"); */
  background-size: cover;
  color: white;
  /* overflow: hidden; */
}

.contact-header-text {
  padding-top: 100px;
  font-size: 42px;
  font-weight: 500;
}

.contact-form {
  padding-top: 100px;
  padding-bottom: 100px;
}

.contact-form h3 {
  font-weight: 300;
}

.footer {
  /* height: 200px; */
  color: white;
  background-color: black;
}

.footer .words {
  padding-top: 50px;
  padding-bottom: 10px;
}

.words img {
  height: 50px;
  margin-bottom: -100px;
}

.words p {
  font-weight: 300;
  font-size: 15px;
  color: white;
  text-align: left;
}

.words p a {
  color: white;
}

@media (max-width: 479px) {
  .words img {
    /* margin-top: -300px; */
    height: 50px;
    padding-top: 10px;
  }

  .foot h3 {
    padding-top: 100px;
  }
}

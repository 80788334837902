.heroabout {
  /* height: 700px; */
  background-image: linear-gradient(
      to right,
      rgba(17, 19, 30, 0.7),
      rgba(17, 19, 30, 0.7)
    ),
    url("../../components/Assets/carousel/qualityresult.jpg");
  padding: 50px 0;
  text-align: center;

  /* background-image: url("map.jpg"); */
  background-size: cover;
  color: white;
  /* overflow: hidden; */
}

.about-header-text {
  padding-top: 100px;
  font-size: 42px;
  font-weight: 500;
}

.aboutheadercontainer {
  width: 90%;
  font-size: 30px;
  font-weight: 500;
  color: grey;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.who {
  padding: 120px 0;
  text-align: center;
}

.whobg {
  font-size: 46px;
  font-weight: 700;
}

.team {
  /* height: 700px; */
  background-image: linear-gradient(
    to right,
    rgba(168, 172, 197, 0.3),
    rgba(255, 255, 255, 0.5)
  );
  padding: 120px 0;

  /* background-image: url("map.jpg"); */
  background-size: cover;
  color: white;
}

.team-head {
  font-size: 45px;
  color: black;
  font-weight: 500;
}

.profilecard {
  padding-top: 30px;
  height: 350px;
  background-color: rgba(17, 19, 30, 0.96);

  text-align: center;
}
.team-image img {
  border-radius: 30%;
  height: 200px;
}

.profile-name {
  padding-top: 15px;
  font-size: 25px;
  font-weight: 600;
  color: white;
}
.profile-rank {
  padding-top: 10px;
  font-size: 15px;
  font-weight: 600;
  color: white;
}

.start {
  padding-top: 50px;
  background-image: linear-gradient(
      to right,
      rgba(17, 19, 30, 0.8),
      rgba(17, 19, 30, 0.8)
    ),
    url("../../components/Assets/carousel/qualityresult.jpg");
  /* background-color: rgba(203, 38, 19, 0.7); */
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.startcontainer {
  padding-bottom: 100px;
}

.start h3 {
  color: white;
  font-size: 35px;
  font-weight: 700;
  padding-top: 50px;
}

.start p {
  width: 50%;
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.button1 {
  width: 250px;
  height: 80px;
  margin-right: 20px;
  margin-top: 20px;
  color: rgba(17, 19, 30, 0.8);
}

.button2 {
  width: 250px;
  height: 80px;
  padding-top: 25px;
  margin-right: 20px;
  margin-top: 20px;
  color: white;
  background-color: #d8855c;
}

@media (max-width: 479px) {
  .profilecard {
    /* padding-top: 30px; */
    margin-bottom: 10px;
    height: 350px;
    background-color: rgba(17, 19, 30, 0.96);

    text-align: center;
  }

  .start h3 {
    color: white;
    font-size: 30px;
    font-weight: 600;
    padding-top: 30px;
  }

  .start p {
    width: 80%;
    color: white;
    font-size: 15px;
    font-weight: 500;
    padding-top: 30px;
  }

  .button1 {
    width: 200px;
    height: 60px;
    margin-right: 20px;
    margin-top: 20px;
    color: rgba(17, 19, 30, 0.8);
  }

  .button2 {
    width: 200px;
    height: 60px;
    margin-right: 20px;
    margin-top: 20px;
    color: white;
    background-color: #d8855c;
  }
}

.whoarewe {
  width: 70%;
}

.whoarewe h3 {
  font-weight: 300;
  font-size: 40px;
}

.whoarewe p {
  padding-top: 20px;
  font-weight: 250;
  font-size: 16px;
  color: black;
}

.mission h5 {
  font-weight: 300;
  font-size: 26px;
  text-align: center;
}

.mission p {
  font-weight: 250;
  font-size: 16px;
  color: black;
  text-align: center;
}

.mission h2 {
  font-size: 100px;
  color: #d8855c;
  text-align: center;
}

.service {
  /* height: 500px; */
  padding-top: 100px;
  margin-top: 100px;
  background-color: rgb(246, 248, 251);
}

.service .head {
  width: 60%;
}

.service h3 {
  font-weight: 300;
  font-size: 40px;
}

.service p {
  padding-top: 20px;
  font-weight: 250;
  font-size: 16px;
  color: black;
}

.category {
  padding-top: 50px;
}

.category img {
  height: 500px;
  opacity: 0.03;
  margin-top: -200px;
  overflow: hidden;
}

.category .roww {
  margin-top: -250px;
  padding-bottom: 100px;
}

.category h5 {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}

.category p {
  font-weight: 250;
  font-size: 15px;
  color: black;
  text-align: center;
}

.category h2 {
  font-size: 100px;
  color: #d8855c;
  text-align: center;
}

.guarantee {
  /* height: 500px; */
  padding-top: 50px;
  margin-top: 100px;
  padding-bottom: 100px;
  width: 50%;
}

.guarantee h3 {
  font-weight: 300;
  font-size: 40px;
}

.guarantee p {
  padding-top: 20px;
  font-weight: 250;
  font-size: 16px;
  color: black;
}

@media (max-width: 479px) {
  .whoarewe {
    width: 100%;
  }
  .whoarewe h3 {
    font-weight: 400;
  }

  .whoarewe p {
    padding-top: 20px;
    font-weight: 200;
    font-size: 17px;
  }
  .service .head {
    width: 100%;
  }
  .category img {
    height: 250px;
    opacity: 0.03;
    margin-top: -100px;
    overflow: hidden;
  }
  .category .roww {
    margin-top: -150px;
  }

  .guarantee {
    /* height: 500px; */
    padding-top: 50px;
    margin-top: 100px;
    padding-bottom: 100px;
    width: 80%;
  }
}

.herotraining {
  /* height: 700px; */
  background-image: linear-gradient(
      to right,
      rgba(17, 19, 30, 0.7),
      rgba(17, 19, 30, 0.7)
    ),
    url("../../components/Assets/carousel/team.jpg");
  padding: 100px 0;
  text-align: center;

  /* background-image: url("map.jpg"); */
  background-size: cover;
  color: white;
  /* overflow: hidden; */
}

.training-header-text {
  padding-top: 100px;
  font-size: 42px;
  font-weight: 500;
}

.train {
  padding-top: 50px;
  background-image: linear-gradient(
      to right,
      rgb(87, 151, 145),
      rgb(73, 126, 168)
    ),
    url("../../components/Assets/Images/solution.jpg");
  /* background-color: rgba(203, 38, 19, 0.7); */
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.train h3 {
  color: white;
  font-size: 44px;
  font-weight: 600;
  padding-top: 10px;
}

.train p {
  width: 100%;
  color: white;
  font-size: 20px;
  font-weight: 200;
  padding-bottom: 100px;
}

.preparing {
  margin-top: -30px;
  padding-top: 20px;
  background-color: #262c39;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.preparing h4 {
  padding-top: 10px;
  color: orange;
  font-weight: bold;
  font-size: 15px;
}

.preparing h3 {
  color: white;
  font-size: 17px;
  font-weight: bold;
}

.preparing p {
  color: white;
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 20px;
}

.trainbody {
  padding-top: 50px;
  padding-bottom: 50px;
}

.trainbody h4 {
  font-size: 18px;
  font-weight: 300;
}

.trainbody h3 {
  padding-top: 10px;
  font-size: 20px;
  font-weight: 700;
}

.trainbody li,
p {
  font-size: 14px;
  font-weight: 400;
}

.trainbody iframe {
  width: 420px;
  height: 315px;
}

@media (max-width: 479px) {
  .train h3 {
    color: white;
    font-size: 40px;
    font-weight: 400;
    padding-top: 10px;
  }
  .train p {
    width: 100%;
    color: white;
    font-size: 15px;
    font-weight: 300;
    padding-bottom: 100px;
  }
  .trainbody iframe {
    width: 300px;
    height: 315px;
  }
}

.bar {
  margin-left: 0px;
}

.brand {
  margin-left: 0px;
}
/* #2b2828; #1e1b1b */

.navbar-header {
  background-color: #1e1b1b;
}

/* .nav-item:hover .dropdown-menu {
  display: block !important;
} */

#nav-text {
  /* background-color: green; */
  color: rgb(160, 156, 156);
}

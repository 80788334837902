.heroservice {
  /* height: 700px; */
  background-image: linear-gradient(
      to right,
      rgba(17, 19, 30, 0.7),
      rgba(17, 19, 30, 0.7)
    ),
    url("../../components/Assets/carousel/training.jpg");
  padding: 100px 0;
  text-align: center;

  /* background-image: url("map.jpg"); */
  background-size: cover;
  color: white;
  /* overflow: hidden; */
}

.service-header-text {
  padding-top: 100px;
  font-size: 42px;
  font-weight: 500;
}

.proud {
  padding-top: 100px;
  /* padding-left: 0px; */
}

.head-text {
  margin-top: 0px;
}

#solve {
  font-size: 80px;
  font-weight: 500;
  color: rgba(203, 38, 19, 0.605);
}

#problem {
  font-size: 80px;
  font-weight: 500;
  color: grey;
}

.staffing {
  padding-top: 5px;
}

.staffing #head {
  font-weight: 300;
  font-size: 16px;
  color: rgb(250, 0, 42);
}

.staffing #middle {
  margin-top: -10px;
  font-weight: 300;
  color: black;
  font-size: 30px;
}

.staffing #body {
  margin-top: -10px;
  font-weight: 400;
  color: grey;
  font-size: 16;
}

.servicestart {
  padding-top: 50px;
  background-image: linear-gradient(
      to right,
      rgba(17, 19, 30, 0.5),
      rgba(17, 19, 30, 0.5)
    ),
    url("../../components/Assets/Images/solution.jpg");
  /* background-color: rgba(203, 38, 19, 0.7); */
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.startcontainer {
  padding-bottom: 100px;
}

.servicestart h3 {
  color: white;
  font-size: 35px;
  font-weight: 700;
  padding-top: 50px;
}

.servicestart p {
  width: 100%;
  color: rgb(235, 221, 221);
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 479px) {
  .proud {
    padding-top: 50px;
    padding-left: 50px;
  }
  .head-text {
    margin-top: 0px;
  }
  #solve {
    font-size: 36px;
    font-weight: 700;
    color: rgba(203, 38, 19, 0.605);
  }
  #problem {
    font-size: 36px;
    font-weight: 700;
    color: grey;
  }
  .staffing {
    padding-top: 50px;
  }
  .startcontainer {
    padding-bottom: 100px;
    padding-left: 40px;
  }
}

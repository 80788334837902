.hero img {
  height: 900px;
  width: inherit;
  opacity: 0.5;
}

.images {
  background-image: linear-gradient(
    to right,
    rgba(17, 19, 30, 1),
    rgba(17, 19, 30, 1)
  );
}

.heroText {
  margin-bottom: 350px;
}

.heroText h3 {
  font-size: 60px;
  font-weight: 500;
}

.heroText p {
  font-size: 30px;
  font-weight: 300;
}

@media (max-width: 479px) {
  .hero img {
    height: 400px;
    width: inherit;
    opacity: 0.5;
  }

  .heroText {
    margin-bottom: 50px;
  }

  .heroText h3 {
    font-size: 30px;
    font-weight: bold;
  }

  .heroText p {
    font-size: 20px;
  }
  
}
